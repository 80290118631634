import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
//import bg from '../assets/images/pexels-ksenia-chernaya-3965543HD.jpg';
import fernando from '../assets/images/foto-fernando.jpg';

import icl from '../assets/images/ICL.jpg';
import lmi from '../assets/images/LMI.png';

import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';

const AlianzaStyles = styled.div`
  div {
    padding: 1rem 15%;
    background-color: #ffffffab;
    backdrop-filter: blur(52px);
    &.header {
      padding: 1rem 3rem;
      background: transparent;
    }

    img {
      padding: 3rem;
      max-width: 680px;
      margin: 0 auto;
    }

    ul {
      text-align: left;
    }
  }

  @media (max-width: 800px) {
    height: auto;
    h1 {
      font-size: 3rem;
    }
    div {
      padding: 1rem 2.2rem;
      &.header {
        padding: 1rem 2rem;
        background: transparent;
      }

        img {
        padding: 0;
        max-width: 260px;
        margin: 0 auto;
        }
    }
  }
`;

export default function MiPage() {
  return (
    <AlianzaStyles>
      <Article title="Sobre mí">
        <>

          <img src={fernando}></img>
          <p>
            Soy un ingeniero de alimentos especializado en Administración de la
            Calidad Total y la Productividad. Poseo una amplia experiencia en
            este campo, remontándose al año de 1981, cuando comencé a trabajar
            en la industria manufacturera con productos Quaker y  Adams
             en cargos directivos, en los
            cuales, como parte de mis funciones principales debía reportar
            directamente a la presidencia de las compañías y a los directores
            regionales e internacionales los asuntos de Calidad, Medio Ambiente, Seguridad Industrial, Salud Ocupacional,
            Inocuidad Alimentaria, Desarrollo de Nuevos productos y Asuntos
            Regulatorios. Mi responsabilidad directa en estos asuntos era con la
            Región Andina, e igualmente, trabajaba en conjunto con otras
            filiales de las corporaciones en países como Brasil, Canadá, México,
            Estados Unidos e Inglaterra.
          </p>

          <h3 id="COACHING">SERVICIOS DE COACHING Y CONSULTORÍA EMPRESARIAL</h3>
          <p>
            Desde el año 2007 he venido desarrollando mis actividades como
            consultor empresarial independiente, docente, auditor y coach
            en el sector privado y en el sector
            público, tanto en Colombia como en otros países para más de 160
            organizaciones entre las que destaco: industrias alimenticias,
            industrias farmacéuticas, petroleras, head hunters, industrias de
            empaques, operadores petroleros, colegios, universidades,
            constructoras, servicios funerarios, frigoríficos, tecnología e
            información, ministerios, ejército, policía, gobernaciones,
            alcaldías, corporaciones autónomas, textileras, clínicas,
            hospitales, empresas de salud, empresas municipales, exploradoras de
            gas, destiladores de alcohol, empresas de empaques, farmacéuticas e
            ingenios azucareros. Entre los servicios ofrecidos, cuento con la
            siguiente gama de especialidades:
          </p>
          <br />
          <ul class="c12 lst-kix_list_1-0 start style-scope news-article">
            <li class="c10 style-scope news-article">
              <span class="c6 c17 style-scope news-article">Coaching</span>
              <span class="c7 style-scope news-article">
                &nbsp;personal, ejecutivo y&nbsp;organizacional.
              </span>
            </li>
            <li class="c10 style-scope news-article">
              <span class="c7 style-scope news-article">
                Consultorías&nbsp;y formación gerencial/empresarial en sistemas
                de gestión integrada SHEQ
              </span>
              <span class="c7 c15 style-scope news-article">
                &nbsp;(siglas en inglés para{' '}
              </span>
              <span class="c4 style-scope news-article">Safety</span>
              <span class="c7 c15 style-scope news-article">, </span>
              <span class="c4 style-scope news-article">Health</span>
              <span class="c7 c15 style-scope news-article">, </span>
              <span class="c4 style-scope news-article">Environment</span>
              <span class="c7 c15 style-scope news-article">&nbsp;</span>
              <span class="c4 style-scope news-article">and</span>
              <span class="c7 c15 style-scope news-article">&nbsp;</span>
              <span class="c4 style-scope news-article">Quality</span>
              <span class="c7 c15 style-scope news-article">)</span>
              <span class="c7 style-scope news-article">
                &nbsp;para manejo de la estrategia, riesgo y mejoramiento.
              </span>
            </li>
            <li class="c10 style-scope news-article">
              <span class="c7 style-scope news-article">
                Auditorías de certificación, seguimiento y renovación para
                Icontec.
              </span>
            </li>
            <li class="c10 style-scope news-article">
              <span class="c7 style-scope news-article">
                Auditorías internas empresariales&nbsp;para planes de
                mejoramiento.
              </span>
            </li>
            <li class="c10 style-scope news-article">
              <span class="c7 style-scope news-article">
                Formación en Inocuidad Alimentaria (BMP, HACCP, ISO-TS 22002-1,
                ISO-TS 22002-4, ISO 22000, FSSC 22000).
              </span>
            </li>
            <li class="c10 style-scope news-article">
              <span class="c7 style-scope news-article">
                Formación en gestión del riesgo, productividad y estrategia.
              </span>
            </li>
            <li class="c10 style-scope news-article">
              <span class="c7 style-scope news-article">
                Formación en sistemas de gestión de calidad para el sector
                público.
              </span>
            </li>
            <li class="c10 style-scope news-article">
              <span class="c7 style-scope news-article">
                Formación de auditores en técnicas de auditoría y habilidades
                para evaluar sistemas de gestión.
              </span>
            </li>
          </ul>

          
          <br />

          <h3>FORMACIÓN COMO COACH</h3>
          <p>
          Estoy certificado por el ICL (siglas en inglés para International Coaching Leadership) como coach de vida, ejecutivo y organizacional, con énfasis en liderazgo, por la escuela de Ron Jenson Future Achievement International. También estoy certificado por el LMI (Leadership Management International) en coaching para liderazgo directivo con la escuela de Paul J. Meyer.
          </p>

          <img src={icl}>
           </img>
           <img src={lmi}>
           </img>

            <h3 id="SERVICIOS">SERVICIOS DESTACADOS EN MI TRAYECTORIA</h3>
            <p>Con el Icontec (Instituto Colombiano de Normalización y Certificación) he estado vinculado desde el año 2007 como docente en varios temas para sistemas de gestión de calidad, medio ambiente, seguridad industrial, salud, inocuidad alimentaria y riesgo. En esta misma institución soy auditor líder para ISO 9001:2015, HACCP (NTC 5830), ISO-TS 22002-1, ISO-TS 22002-4, ISO 22000. Los servicios que presto a Icontec, tanto en docencia como en auditorias, son a nivel nacional e internacional.</p>
            <p>Otro frente en el que me desempeño es la consultoría, también en los campos de calidad, ambiental, SST, riesgo, inocuidad y auditorías internas con énfasis en acompañamiento coaching como un valor agregado, donde no solo se trabaja el desarrollo hacia el desempeño en la organización, sino también hacia el desempeño como individuo.</p>
            <p>El tercer frente de servicios es el coaching a nivel personal, ejecutivo y organizacional, para dar ese impulso a las personas en sus propósitos individuales, como profesional o como organización. Son trabajos cortos donde se obtiene como resultado la liberación del potencial escondido que no permite afrontar los retos del día en nuestras diferentes actividades y compromisos.</p>
            <p>Para los tres campos de acción, hay un buen recorrido por empresas en diferentes sectores de la industria y de servicio, como petroleras, constructoras, servicios funerarios, industrias de alimentos, farmacéuticas, colegios, universidades, alcaldías, gobernaciones, corporaciones autónomas regionales, tecnología de la información, policía, ejército, fuerza aérea, naval, clínicas hospitales, IPS, ingenios azucareros, destiladoras de alcohol, licoreras, exploradoras de gas, ministerios, industrias de empaques, entre otras, así como trabajos individuales personales y ejecutivos.</p>
        
        <br />
        <Link to="/mision"><h3>Misión</h3></Link>
        <br />
        </>
      </Article>
    </AlianzaStyles>
  );
}
